export const licenseIds = {
  sweden: {
    license: 12,
    name: 'Sweden (White Label)',
    boxIconColor: '#993838',
    boxIconBackColor: '#ffecec'
  },
  uk: {
    license: 1,
    name: 'UK (White Label)',
    boxIconColor: '#47b4a9',
    boxIconBackColor: '#f4fbfa'
  },
  mga: {
    license: 9,
    name: 'MGA (White Label)',
    boxIconColor: '#6394ce',
    boxIconBackColor: '#f6f9fc'
  },
  france: {
    license: 2,
    name: 'France (White Label)',
    boxIconColor: '#ffad5f',
    boxIconBackColor: '#fffaf5'
  },
  italy: {
    license: 16,
    name: 'Italy (White Label)',
    boxIconColor: '#ff6c6c',
    boxIconBackColor: '#ffd5d5'
  },
  ontario: {
    license: 24,
    name: 'Ontario (White Label)',
    boxIconColor: '#8a8a5c',
    boxIconBackColor: '#ebebe0'
  },
  germany: {
    license: 17,
    name: 'Germany (White Label)',
    boxIconColor: '#88903a',
    boxIconBackColor: '#eef2c7'
  },
  brazilian: {
    license: 21,
    name: 'Brazilian (White Label)',
    boxIconColor: '#ff6c6c',
    boxIconBackColor: '#ffd5d5'
  },
  netherlands: {
    license: 23,
    name: 'Netherlands (White Label)',
    boxIconColor: '#c242de',
    boxIconBackColor: '#e2d0e6'
  },
  denmark: {
    license: 3,
    name: 'Denmark (White Label)',
    boxIconColor: '#425164',
    boxIconBackColor: '#e1e1e1'
  },
  us: {
    license: 8,
    name: 'US (White Label)',
    boxIconColor: '#425164',
    boxIconBackColor: '#e1dfca'
  },
  romania: {
    license: 30,
    name: 'Romania (White Label)',
    boxIconColor: '#0c103582',
    boxIconBackColor: '#5265ff30'
  },
  turnkey: {
    name: 'N/A (Turnkey)',
    boxIconColor: '#8d6cd6',
    boxIconBackColor: '#f8f6fd'
  }
};

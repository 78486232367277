export const parseDataWithTotalCount = (response: Object) => {
  const { Models, TotalCount } = response;
  return {
    data: Models,
    totalCount: TotalCount
  };
};

export const roundNumber = (data, key, rate) => {
  if (!data) return null;
  if (data[key] === null) return null;
  const newValue = Math.round(data[key] * rate * 1000000) / 1000000;
  return +newValue;
};

export const roundRate = (data, rate) => {
  if (data === null) return null;
  const newValue = Math.round(data * rate * 100) / 100;
  return +newValue;
};

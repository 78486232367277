/* eslint-disable no-unused-expressions */
/* eslint-disable-next-line array-callback-return */
/* eslint-disable  no-mixed-operators */
/**
 * @flow
 * */
import React, { memo, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

import { getProjectType } from 'lib/helpers/getProjectType';
import useAmlLicense from 'modules/AML/hooks/useAmlLicense';
import NavigationMenu from '@geneui/components/NavigationMenu';
import usePermission from 'hooks/usePermission';
import menuItems from 'config/nav';

import './styles.scss';

type Props = {
  location: Object
}

/**
 * MenuBar
 * @param {Object} location -
 * @returns {*} {JSX.Element} - React.Component
 */
const MenuBar = ({ location }: Props) => {
  const { hasSomeRead, moreThenOneAreReadable } = usePermission();
  const { configs, license, hasOne } = useAmlLicense();

  const { navigation, activeTab } = useMemo(() => {
    let activeTab = 'none';
    let childTab = null;
    const navigation = menuItems[getProjectType()](configs || {}, license, hasOne)
      .map(item => {
        const condition = item.permission && (item.permission === 'show' || item.moreThenOne && moreThenOneAreReadable(item.permission) || !item.moreThenOne && hasSomeRead(item.permission));
        const mainId = item.id.replaceAll('/', '_');
        if (location.pathname.includes(item.id)) {
          activeTab = mainId;
        }

        return {
          ...item,
          id: mainId,
          isHidden: !condition,
          data: item.data?.map(i => {
            const childCondition = i.permission && hasSomeRead(i.permission);
            const sliceUrl = i.id.split('/');
            const id = i.id.replaceAll('/', '_');
            if (
              (sliceUrl.length >= 5 && !license || sliceUrl.length >= 6 && license)
            && location.pathname.includes(sliceUrl.slice(0, sliceUrl.length - 1).join('/'))

            || i.id === location.pathname
            ) {
              childTab = id;
            }
            return {
              ...i,
              id,
              isHidden: !childCondition
            };
          })
        };
      });
    if (childTab) {
      activeTab = `${activeTab}/${childTab}`;
    }
    return { navigation, activeTab };
  },
  [location, configs, license, hasOne, hasSomeRead, moreThenOneAreReadable]);

  return (
    <div className="top-menu-bar flex ">
      <div className="main-menu">
        <NavigationMenu
          data={navigation}
          value={activeTab}
        />
      </div>
    </div>
  );
};

export default memo<Props>(withRouter(MenuBar));
